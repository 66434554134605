import { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { upload_image } from "../../../api/services";

export default function TextArea({ setValue, value }) {
  const editorRef = useRef(null);
  const KEY = process.env.REACT_APP_TINY_KEY;

  // Log the content in the editor
  const log = () => {
    if (editorRef.current) {
      setValue((old) => ({ ...old, content: editorRef.current.getContent() }));
    }
  };

  const handleImageUpload = async (blobInfo) => {
    const formData = new FormData();
    formData.append("upload_img", blobInfo.blob());

    try {
      // Assuming `upload_image` is a service making the API call
      const response = await upload_image(formData);

      if (response.status === 200) {
        const data = response.data;
        delete data.message;
        console.log(data);
        if (data.url) {
          // success("https://www.classbazaar.com/static/media/newBanner.15d757cc7fa8f20929ec.png"); // Pass the image URL to the editor
          return data.url;
        } else {
          // failure("No URL returned from the server.");
        }
      } else {
        // failure("Image upload failed. Server returned an error.");
      }
    } catch (error) {
      console.error("Image upload error:", error);
      // failure("Image upload failed due to a network or server error.");
    }
  };

  return (
    <>
      <span className="text-gray-600 text-sm">Write Blog content here...</span>
      <Editor
        onChange={() =>
          setValue((old) => ({
            ...old,
            content: editorRef.current.getContent(),
          }))
        }
        apiKey={KEY}
        onInit={(_evt, editor) => (editorRef.current = editor)}
        initialValue={value}
        init={{
          height: 500,
          menubar: true,
          plugins: [
            "advlist autolink lists link image charmap preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code help wordcount",
            "image",
          ],
          toolbar:
            "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | image", // Ensure the image button is in the toolbar
          image_uploadtab: true, // Enable the image upload tab in the image dialog
          automatic_uploads: true, // Enables automatic upload
          images_upload_handler: handleImageUpload, // Set custom image upload handler
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
      />
      {/* <button onClick={log} className="mt-4 p-2 bg-blue-500 text-white">
        Log Editor Content
      </button> */}
    </>
  );
}
