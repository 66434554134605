import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { setAuth } from "../../redux/features/auth";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  let {isAuth} = useSelector(state=>state.auth);

  useEffect(()=>{
    if(isAuth)
      navigate("/blogs")
  },[isAuth])
  

  const handleSubmit = (e) => {
    e.preventDefault();

    // Hardcoded credentials
    const adminEmail = "classbazar19@gmail.com";
    const adminPassword = "cb@2024";

    // Validation
    if (email === adminEmail && password === adminPassword) {
      setError("");
      toast.success("Login successful! Welcome to CB Admin.");
      dispatch(setAuth());
      navigate("/blogs");
    } else {
      toast.error("Invalid email or password. Please try again.");
      setError("");
    }
  };

  return (
    <div className="flex items-center justify-center h-[500px]">
      <div className="w-full max-w-md p-5 space-y-4 bg-white rounded-lg shadow-md">
        <h2 className="text-2xl font-bold text-center text-gray-700">
          CB Admin Login
        </h2>
        {error && <p className="text-sm text-red-500">{error}</p>}
        <form onSubmit={handleSubmit} className="space-y-4">
          {/* Email Input */}
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-3 py-2 mt-1 text-gray-700 border rounded-md focus:ring focus:ring-blue-200 focus:outline-none"
              placeholder="Enter your email"
            />
          </div>

          {/* Password Input */}
          <div>
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-3 py-2 mt-1 text-gray-700 border rounded-md focus:ring focus:ring-blue-200 focus:outline-none"
              placeholder="Enter your password"
            />
          </div>

          {/* Submit Button */}
          <div>
            <button
              type="submit"
              className="w-full px-4 py-2 font-bold text-white bg-primary rounded-md hover:bg-orange-400 focus:outline-none focus:ring focus:ring-blue-300"
            >
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
