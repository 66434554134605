import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleNav } from "../../redux/features/general";
import { Link, useLocation } from "react-router-dom";

function SideNav() {
  const { open } = useSelector((state) => state.general);
  const dispatch = useDispatch();
  const location = useLocation();

  const list = [
    {
      label: "Blogs",
      link: "/blogs",
      icon: (
        <svg
          className={`${
            location.pathname === "/blogs" ? "fill-primary" : "fill-gray-600"
          } group-hover:fill-primary`}
          width={30}
          height={25}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <title>post-outline</title>
          <path d="M19 5V19H5V5H19M21 3H3V21H21V3M17 17H7V16H17V17M17 15H7V14H17V15M17 12H7V7H17V12Z" />
        </svg>
      ),
    },
  ];

  return (
    <div
      className={`fixed w-[200px] top-0 p-4 flex flex-col gap-5 bg-white shadow-lg border h-[100vh] transition-transform duration-500 ${
        open ? "translate-x-0" : "-translate-x-full"
      }`}
      style={{
        left: 0,
        visibility: open ? "visible" : "hidden",
        transitionDelay: open ? "0s" : "0.5s", // Delays hiding after animation
      }}
    >
      <button
        className="self-end group"
        onClick={() => dispatch(toggleNav())}
      >
        <svg
          className="fill-gray-600 group-hover:fill-primary"
          width={20}
          height={25}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <title>close</title>
          <path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
        </svg>
      </button>
      <ul>
        {list.map((row) => (
          <li key={row.link}>
            <Link className="flex cursor-pointer gap-4 p-1 group" to={row.link}>
              {row.icon}
              <span className="text-gray-600">{row.label}</span>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default SideNav;
