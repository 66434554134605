import React, { useEffect, useState } from "react";
import TextArea from "./TinyMce";
import Previews from "./ImageDropzone";
import { create_blog, list_tag } from "../../../api/services";
import toast from "react-hot-toast";
import { toggleForm, toggleRefresh } from "../../../redux/features/general";
import { useDispatch, useSelector } from "react-redux";

function BlogForm() {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.general);
  const [value, setValue] = useState({ ...initialState });
  const [tags, setTags] = useState([]);
  const [tagArray, setTagArray] = useState([]);

  useEffect(() => {
    fetchedList();
  }, []);

  function handleValue(e) {
    if (tagArray.includes(e.target.name))
      setValue((old) => ({
        ...old,
        [e.target.name]: e.target.checked,
      }));
    else
    setValue((old) => ({
      ...old,
      [e.target.name]:
        e.target.name === "is_active"
          ? e.target.checked
            ? 1
            : 0
          : e.target.value,
    }));
  }

  async function handleSubmit(e) {
    try {
      e.preventDefault();
      console.log(value);
      if (!value.card_image) {
        toast.error("Please add the Card/Featured image.");
        return 0;
      }
      const res = await create_blog(value);
      if (res.status === 200) {
        toast.success("Blogs added successfully ");
        dispatch(toggleForm());
        dispatch(toggleRefresh());
        setValue({ ...initialState });
      } else toast.error("Error while adding the blog.");
    } catch (error) {
      toast.error("Error while adding the blog.");
    }
  }

  async function fetchedList() {
    try {
      let response = await list_tag();
      console.log(response);
      if (response.status !== 200) return toast.error("Error in tag loading.");

      let { list } = response.data;
      setTags([...list]);
      setTagArray([...list.map((row) => row.tag_name + " Tag")]);
    } catch (error) {
      return toast.error("Error in tag loading.");
    }
  }

  return (
    <div className="flex flex-col gap-5">
      <div className="header text-gray-600 flex flex-col gap-1">
        <span className="text-2xl">Create Blog</span>
        <span className="text-xs">Write some beautiful blog here...</span>
      </div>
      {/* // form started */}
      <form onSubmit={handleSubmit} className="flex flex-col gap-3">
        <input
          onChange={handleValue}
          value={value.title}
          className={inputClass}
          required
          placeholder="Blog Title"
          name="title"
        ></input>
        <Previews value={value.card_image} setValue={setValue} />
        <input
          onChange={handleValue}
          value={value.alt}
          className={inputClass}
          required
          placeholder="Alt Name"
          name="alt"
        ></input>
        <TextArea value={value.content} setValue={setValue} />
        <textarea
          required
          onChange={handleValue}
          value={value.description}
          className={inputClass}
          style={{ resize: "none" }}
          placeholder="Write a description here..."
          name="description"
        ></textarea>
        <input
          required
          onChange={handleValue}
          value={value.author}
          className={inputClass}
          placeholder="Author"
          name="author"
        ></input>
        <input
          className={inputClass}
          placeholder="Meta Title"
          name="meta_title"
          onChange={handleValue}
          required
          value={value.seo_title}
        ></input>
        <input
          className={inputClass}
          placeholder="Meta Description"
          name="meta_description"
          required
          onChange={handleValue}
          value={value.seo_keywords}
        ></input>
        {/* // Tag Checks  */}
        <div className="flex gap-1 text-gray-500 flex-wrap mb-2">
          <p className="text-gray-500 text-sm mb-1 w-full">
            Select some popular tag from here...
          </p>
          {tags.length !== 0 &&
            tags.map((row, index) => (
              <label
                key={index}
                className="flex items-center space-x-2 text-sm"
              >
                <input
                  type="checkbox"
                  checked={value[`${row.tag_name} Tag`]}
                  name={`${row.tag_name} Tag`}
                  onChange={handleValue}
                  className="hidden peer"
                />
                <div className="h-4 p-2 w-4 border-2 border-gray-300 rounded-md flex items-center justify-center peer-checked:bg-primary">
                  <svg
                    width={25}
                    height={25}
                    className="h-3 w-3 fill-white peer-checked:block hidden"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <title>check</title>
                    <path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                  </svg>
                </div>
                <span className="text-gray-600">{row.tag_name}</span>
              </label>
            ))}
        </div>
        {/* // Tag Checks ends */}
        <label className="flex items-center space-x-2">
          <input
            type="checkbox"
            checked={value.is_active}
            name="is_active"
            onChange={handleValue}
            className="hidden peer"
          />
          <div className="h-4 p-2 w-4 border-2 border-gray-300 rounded-md flex items-center justify-center peer-checked:bg-primary">
            <svg
              width={25}
              height={25}
              className="h-3 w-3 fill-white peer-checked:block hidden"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <title>check</title>
              <path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
            </svg>
          </div>
          <span className="text-gray-600">
            Check the box to make the blog active.
          </span>
        </label>
        <button
          disabled={loading}
          type="submit"
          className="bg-primary transition duration-300 hover:bg-white group hover:text-primary border-primary border p-2 text-white font-semibold rounded-lg flex gap-1 justify-center items-center"
        >
          {loading ? (
            <>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                className="animate-spin group-hover:fill-primary fill-white"
                width={30}
                height={25}
              >
                <title>loading</title>
                <path d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
              </svg>
            </>
          ) : (
            <div className="flex items-center">
              <svg
                className=" group-hover:fill-primary fill-white"
                width={30}
                height={25}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <title>plus</title>
                <path d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
              </svg>
              Create Blog
            </div>
          )}
        </button>
      </form>
      {/* // form end */}
    </div>
  );
}

const inputClass = "outline-primary p-3 border rounded-lg";

const initialState = {
  title: "",
  alt: "",
  meta_title: "",
  meta_description: "",
  author: "",
  card_image: "",
  is_active : false,
  description: "",
  content: "",
};
export default BlogForm;
