import React, { useEffect } from "react";
import "../App.css";
import { Toaster } from "react-hot-toast";
import Navbar from "./utils/Navbar";
import { useLocation } from "react-router-dom";
import Sidenav from "./utils/SideNav";
import FormWrapper from "./utils/FormWrapper";


function Layout({ children }) {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll to top
    });
  }, [pathname]); // This effect runs every time the pathname changes

  return (
    <div className="relative flex flex-col max-w-[1400px] m-auto">
      <Navbar />
      <FormWrapper/>
      <Sidenav></Sidenav>
      <main className="p-8">{children}</main>
      <Toaster position="top-right" />
    </div>
  );
}

export default Layout;
