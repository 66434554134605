import React, { useEffect, useState } from "react";
import { meta_details } from "../../../api/services";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

function Dashboard() {
  let { refresh } = useSelector((state) => state.general);
  let [count, setCount] = useState({
    total: 0,
    active: 0,
    inactive: 0,
  });

  useEffect(() => {
    fetchMeta();
  }, [refresh]);

  async function fetchMeta(params) {
    try {
      let res = await meta_details();
      if (res.status !== 200) toast.error("Something went wrong !!!");
      else {
        let { total, active, inactive } = res?.data?.meta;
        setCount({
          total : total || 0,
          active : active || 0,
          inactive : inactive || 0,
        });
      }
    } catch (error) {
      toast.error("Something went wrong !!!");
    }
  }
  return (
    <div className="flex justify-between gap-3">
      {data.map((row) => (
        <div className="w-[300px] min-w-[150px] flex-wrap max-w-[300px] h-[150px] gap-2 p-3 flex flex-col justify-center items-center text-white font-semibold rounded-md bg-primary">
          {row.icon}
          <span className="text-3xl">{count[row.state] || 0} </span>
          <span className="text-lg">{row.label}</span>
        </div>
      ))}
    </div>
  );
}

let data = [
  {
    label: "Total Blogs",
    state: "total",
    icon: (
      <svg
        width={25}
        height={25}
        fill="white"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <title>list-box</title>
        <path d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3M7 7H9V9H7V7M7 11H9V13H7V11M7 15H9V17H7V15M17 17H11V15H17V17M17 13H11V11H17V13M17 9H11V7H17V9Z" />
      </svg>
    ),
  },
  {
    label: "Active Blogs",
    state: "active",
    icon: (
      <svg 
      width={25}
      height={25}
      fill="white"

      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>check-all</title><path d="M0.41,13.41L6,19L7.41,17.58L1.83,12M22.24,5.58L11.66,16.17L7.5,12L6.07,13.41L11.66,19L23.66,7M18,7L16.59,5.58L10.24,11.93L11.66,13.34L18,7Z" /></svg>    
    ),
  },
  {
    label: "Inactive Blogs",
    state: "inactive",
    icon: (
      <svg
      width={25}
      height={25}
      fill="white"
      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>playlist-remove</title><path d="M14 10H3V12H14V10M14 6H3V8H14V6M3 16H10V14H3V16M14.4 22L17 19.4L19.6 22L21 20.6L18.4 18L21 15.4L19.6 14L17 16.6L14.4 14L13 15.4L15.6 18L13 20.6L14.4 22Z" /></svg>
    ),
  },
  {
    label: "Listed Blogs",
    icon: (
      <svg
        width={20}
        height={20}
        fill="white"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <title>list-box</title>
        <path d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3M7 7H9V9H7V7M7 11H9V13H7V11M7 15H9V17H7V15M17 17H11V15H17V17M17 13H11V11H17V13M17 9H11V7H17V9Z" />
      </svg>
    ),
  },
];
export default Dashboard;
