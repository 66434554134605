// src/features/counter/counterSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAuth : false,
  };

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (state) => {
      state.isAuth = !state.isAuth;
    },
  },
});

export const { setAuth } = authSlice.actions;
export default authSlice.reducer;
