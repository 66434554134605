import React from "react";
import { toggleForm } from "../../../redux/features/general";
import { useDispatch } from "react-redux";

function Console() {
  let dispatch = useDispatch();
  return (
    <div className="flex items-center gap-3">
      <input
        className="focus:outline-primary border w-full p-2 rounded-md"
        placeholder="Search by title..."
        name="title"
        value={""}
      ></input>
      <button
        onClick={() => dispatch(toggleForm({ form: "create_blog" }))}
        className="group  hover:bg-transparent border border-primary bg-primary p-3 rounded-md hover:scale-90 duration-200 transition-all"
      >
        <svg
          width={20}
          height={20}
          className="group-hover:fill-primary fill-white"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <title>plus</title>
          <path d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
        </svg>
      </button>
      <button
        onClick={() => dispatch(toggleForm({ form: "add_tag" }))}
        className="group  hover:bg-transparent border border-primary bg-primary p-3 rounded-md hover:scale-90 duration-200 transition-all flex justify-center items-center gap-3 text-white"
      >
        <svg
          width={20}
          height={20}
          className="group-hover:fill-primary fill-white"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <title>tag-plus</title>
          <path d="M21.41,11.58L12.41,2.58C12.04,2.21 11.53,2 11,2H4A2,2 0 0,0 2,4V11C2,11.53 2.21,12.04 2.59,12.41L3,12.81C3.9,12.27 4.94,12 6,12A6,6 0 0,1 12,18C12,19.06 11.72,20.09 11.18,21L11.58,21.4C11.95,21.78 12.47,22 13,22C13.53,22 14.04,21.79 14.41,21.41L21.41,14.41C21.79,14.04 22,13.53 22,13C22,12.47 21.79,11.96 21.41,11.58M5.5,7A1.5,1.5 0 0,1 4,5.5A1.5,1.5 0 0,1 5.5,4A1.5,1.5 0 0,1 7,5.5A1.5,1.5 0 0,1 5.5,7M10,19H7V22H5V19H2V17H5V14H7V17H10V19Z" />
        </svg>
      </button>
    </div>
  );
}

export default Console;
