// src/features/counter/counterSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
  open_form: false,
  form : 'create_blog',
  loading : false,
  refresh : false,
  slug : null
};

const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    toggleNav: (state) => {
      state.open = !state.open;
    },
    toggleRefresh: (state) => {
      state.refresh = !state.refresh;
    },
    toggleLoader: (state) => {
      state.loading = !state.loading;
    },
    toggleForm: (state,action) => {
      state.open_form = !state.open_form;
      state.slug = action?.payload?.slug || null;
      state.form = action?.payload?.form || null;
    },
  },
});

export const { toggleNav,toggleRefresh, toggleForm, toggleLoader } = generalSlice.actions;
export default generalSlice.reducer;
