import React from "react";
// logo
import logo from "../../assets/img/common/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { toggleNav } from "../../redux/features/general";
import { setAuth } from "../../redux/features/auth";

function Navbar() {
  let dispatch = useDispatch();
  let { isAuth } = useSelector((state) => state.auth);
  return (
    <div className="w-full border p-4 flex items-center justify-between relative">
      <div>
        {isAuth === true && (
          <button className="group" onClick={() => dispatch(toggleNav())}>
            <svg
              className="group-hover:fill-primary fill-gray-600"
              width={30}
              height={25}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <title>menu</title>
              <path d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z" />
            </svg>
          </button>
        )}
      </div>
      <div>
        <img src={logo} className="w-[130px]" alt="logo" />
      </div>
      <div className="flex gap-4">
        <button className="group">
          <svg
            className="group-hover:fill-primary fill-gray-600"
            width={30}
            height={25}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <title>account</title>
            <path d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z" />
          </svg>
        </button>
        {isAuth === true && (
          <button onClick={() => dispatch(setAuth())} className="group">
            <svg
              className="group-hover:fill-primary fill-gray-600"
              width={30}
              height={25}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <title>logout</title>
              <path d="M17 7L15.59 8.41L18.17 11H8V13H18.17L15.59 15.58L17 17L22 12M4 5H12V3H4C2.9 3 2 3.9 2 5V19C2 20.1 2.9 21 4 21H12V19H4V5Z" />
            </svg>
          </button>
        )}
      </div>
    </div>
  );
}

export default Navbar;
