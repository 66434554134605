import React, { useEffect } from 'react'
import Table from './components/Table'
import Dashboard from './components/Dashboard'
import Console from './components/Console'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

function Blogs() {

  let {isAuth} = useSelector(state=>state.auth);

  const navigate = useNavigate();

  useEffect(()=>{
    if(isAuth === false)
      navigate("/login")
  },[isAuth])
  
  return (
    <div className='flex flex-col gap-8'>
        <Dashboard></Dashboard>
        <Console></Console>
        <Table></Table>
    </div>
  )
}

export default Blogs