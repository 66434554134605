import axios from "axios";
import config from '../config.json'

let {liveURL,localURL, dev} = config

let API = dev ? localURL : liveURL ;

export async function upload_image(data) {
  console.log(data);
  return await axios.post(`${API}/blog/get_URL`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
export async function add_tag(data) {
  return await axios.post(`${API}/blog/add-tag`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
export async function create_blog(data) {
  return await axios.post(`${API}/blog/create_blog`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
export async function edit_blog(data) {
  data.is_active = data.is_active ? 1 : 0;
  return await axios.patch(`${API}/blog//edit-blog`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
export async function get_list(data) {
  let { page, limit } = data;
  return await axios.get(`${API}/blog/get-list?page=${page}&limit=${limit}`);
}
export async function get_blog_details(data) {
  let { slug } = data;
  return await axios.get(`${API}/blog/get-blog-details?slug=${slug}`);
}
export async function meta_details() {
  return await axios.get(`${API}/blog//meta-details`);
}
export async function list_tag() {
  return await axios.get(`${API}/blog/list-tag`);
}
